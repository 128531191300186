.ck.ck-editor{
  width: 100%;
}

.ck-editor__editable{
  height: 803px;
  border-color: #dbdbdb !important;
}

:root{
  --ck-border-radius: 5px;
  --ck-color-toolbar-border: #dbdbdb;
  --ck-color-panel-border: #dbdbdb;
  --ck-color-focus-border: #dbdbdb;
  --ck-color-input-border: #dbdbdb;
  --ck-color-input-text: #dbdbdb;
  --ck-color-text: #949494;
  --ck-color-panel-background: var(--ck-custom-border);
}